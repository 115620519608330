import React, { useState } from "react";
import { Link } from "gatsby";
import styles from "../css/header.module.scss";
import logo from "../images/svg/logo.svg";
import Svgsprite from "./svgsprite";
import { Squash as Hamburger } from "hamburger-react";

function Header(props) {
  const [isOpen, setOpen] = useState(false);

  const Menu = () => (
    <div className={isOpen ? styles.menu_visible : styles.menu_hidden}>
      <ul className={styles.ulmenutoplevel}>
        <li>
          <Link className={styles.menuitem}>yacht telemetry</Link>
          <ul className={styles.uldropdown}>
            <li>
              <Link className={styles.menuitem} to="/charters">
                For charter companies
              </Link>
            </li>
            <li>
              <Link className={styles.menuitem} to="/individuals">
                For individuals
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link className={styles.menuitem}>software</Link>
          <ul className={styles.uldropdown}>
            <li>
              <Link className={styles.menuitem} to="/chartplotter">
                Chartplotter
              </Link>
            </li>
            <li>
              <Link className={styles.menuitem} to="/guard">
                Boatpilot Guard
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link className={styles.menuitem} to="/contacts">
            contacts
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <header
      className={`${styles.header} ${isOpen ? styles.header_fullscreen : ""}`}
    >
      <div className="wrap --fullh">
        <div className={styles.grid}>
          <section className={styles.logo}>
            <Link to="/">
              <Svgsprite
                svgclass={styles.svg_media}
                name={logo.id}
                vbox={logo.viewBox}
              />
            </Link>
          </section>
          <section className={styles.topmenu}>
            <Menu />
          </section>
          <section className={styles.burger}>
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </section>
        </div>
      </div>
    </header>
  );
}

export default Header;
