import React from "react";
import {Link} from "gatsby";
import styles from "../css/footer.module.scss";
import logo_gray from "../images/svg/logo_gray.svg";
import mappoint from "../images/svg/mappoint.svg";
import email from "../images/svg/email.svg";
import phone from "../images/svg/phone.svg";
import Svgsprite from "./svgsprite";

const Footer = () => (
    <footer className={styles.footer}>
        <div className="wrap">
            <div className={styles.logo}>
                <Link to="/">
                    <Svgsprite
                        svgclass={styles.footerlogo}
                        name={logo_gray.id}
                        vbox={logo_gray.viewBox}
                    />
                </Link>
            </div>
            <div className={styles.lists}>
                <div className={styles.item}>
                    <h3>yacht telemetry</h3>
                    <ul>
                        <li>
                            <Link to="/charters">For charter companies</Link>
                        </li>
                        <li>
                            <Link to="/individuals">For individuals</Link>
                        </li>
                        <li></li>
                    </ul>
                </div>
                <div className={styles.item}>
                    <h3>software</h3>
                    <ul>
                        <li>
                            <Link to="/chartplotter">Chartplotter</Link>
                        </li>
                        <li>
                            <Link to="/guard">Boatpilot Guard</Link>
                        </li>
                        <li></li>
                    </ul>
                </div>
                <div className={styles.item}>
                    <h3>links</h3>
                    <ul>
                        <li>
                            <Link to="/terms">EULA, terms of Service</Link>
                        </li>

                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/cookiepolicy">Cookie Policy</Link>
                        </li>

                        <li>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.facebook.com/boatpilot.me/"
                            >
                                Facebook community
                            </a>
                        </li>
                        <li></li>
                    </ul>
                </div>
                <div className={styles.item}>
                    <h3>contacts</h3>
                    <ul>
                        <li>
                            <Svgsprite
                                svgclass={styles.icon}
                                name={mappoint.id}
                                vbox={mappoint.viewBox}
                            />
                            Klimentos 41-43, Klimentos Tower, Office 25, 061, Nicosia,
                            Republic of Cyprus.
                        </li>
                        <li>
                            <Svgsprite
                                svgclass={styles.icon}
                                name={email.id}
                                vbox={email.viewBox}
                            />{" "}
                            info@boatpilot.me
                        </li>
                        <li>
                            <Svgsprite
                                svgclass={styles.icon}
                                name={phone.id}
                                vbox={phone.viewBox}
                            />{" "}
                            +35722007092
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
