import React from "react";
import styles from "../css/cookie.module.scss";
import formcss from "../css/form.module.scss";
//import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";
import { bake_cookie, read_cookie } from "sfcookies";
const cookie_key = "consent";

class Cookiebox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
    this.handleClick = this.handleClick.bind(this);
  }

  checker() {
    const checkc = read_cookie(cookie_key);
    if (!checkc.length > 0) {
      this.setState({ isVisible: true });
    }
  }

  componentDidMount() {
    this.checker();
  }

  handleClick() {
    bake_cookie(cookie_key, "accepted");
    this.setState({ isVisible: false });
  }

  render() {
    return this.state.isVisible ? (
      <div className={styles.cookiebox}>
        <div className="wrap">
          <div className={styles.cookieheadline}>We use cookies</div>
          <div className={styles.cookietext}>
            We use cookies and other tracking technologies to improve your
            browsing experience on our website, to show you personalized content
            and targeted ads, to analyze our website traffic, and to understand
            where our visitors are coming from. By browsing our website, you
            consent to our use of cookies and other tracking technologies.
          </div>
          <button onClick={this.handleClick} className={formcss.button}>
            Accept and close alert
          </button>
        </div>
      </div>
    ) : null;
  }
}

export default Cookiebox;
